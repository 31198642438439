import React from "react"

const SocialLinks = [
  { icon: "fab fa-facebook", url: "https://facebook.com/becauseofartmusic" },
  { icon: "fab fa-instagram", url: "https://instagram.com/because_of_art" },
  { icon: "fab fa-soundcloud", url: "https://soundcloud.com/becauseofart" },
  { icon: "fab fa-twitter", url: "https://twitter.com/becauseofart" },
]

const Social = () => (
  <div className="fixed bottom-0 right-0 py-2 px-8">
    <div className=" flex justify-between">
      {SocialLinks.map((item, key) => (
        <a
          href={item.url}
          className="text-gray-900 hover:text-yellow-60 text-4xl ml-8"
        >
          <i class={item.icon}></i>
        </a>
      ))}
    </div>
  </div>
)

export default Social
