import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = props => {
  const IMAGE_SELECT = props.useImage

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "BOALive.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        position: "fixed",
        overflow: "hidden",
        bottom: "0",
        left: "0",
        right: "auto",
        top: "0",
        width: "40vw",
      }}
    >
      <Img
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center center",
          opacity: "1",
          transition: "opacity 500ms ease 0s",
        }}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    </div>
  )
}

export default Image
