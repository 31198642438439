import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./images/logo"
import Social from "./socials"

import { HeaderLinks } from "../navigation/links"

const Header = props => (
  <header>
    <div className="relative text-gray-900 bg-white h-20 p-6 text-4xl z-60 mb-8 text-right ">
      <div className="hidden lg:block absolute top-0 left-0 z-50">
        <Logo />
      </div>

      <div className="w-full">
        <ul className="flex-col lg:flex-row">
          {HeaderLinks.map((item, key) => (
            <Link to={item.url}>
              <li className="mr-2 uppercase cursor-pointer hover:text-yellow-500 font-bold leading-none">
                {item.title} /
              </li>
            </Link>
          ))}
        </ul>
      </div>

      <div id="socials" className="w-full hidden lg:block">
        <Social />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
