/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withAssetPrefix } from "gatsby"

import "./layout.css"
import "../global.css"

import Logo from "./images/transLogo"
import Image from "./images/hero/image"
import ImageVar2 from "./images/hero/image2"
import ImageVar3 from "./images/hero/image3"
import ImageVar4 from "./images/hero/image4"
import ImageVar5 from "./images/hero/image5"
import ImageVar6 from "./images/hero/image6"
import NavigationComp from "./header"
import MobileNav from "./mobileHeader"

const Layout = (props, { children, background }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const MAIN_IMAGE = props.imageVariation

  return (
    <>
      <div
        className="text-white"
        style={{
          maxWidth: "100vw",
          paddingTop: 0,
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="flex text-gray-900">
            <div className="hidden lg:block w-5/12 h-screen">
              <Logo />

              {MAIN_IMAGE === "1" ? <Image /> : <p>nothing</p>}
              {MAIN_IMAGE === "2" ? <ImageVar2 /> : <p>nothing</p>}
              {MAIN_IMAGE === "3" ? <ImageVar3 /> : <p>nothing</p>}
              {MAIN_IMAGE === "4" ? <ImageVar4 /> : <p>nothing</p>}
              {MAIN_IMAGE === "5" ? <ImageVar5 /> : <p>nothing</p>}
              {MAIN_IMAGE === "6" ? <ImageVar6 /> : <p>nothing</p>}
            </div>

            <div className="w-full lg:w-3/12 ml-8 pt-8">
              {/* Mobile Nav */}
              <div className="lg:hidden">
                <MobileNav />
              </div>

              <main>{props.children}</main>
            </div>

            <div className="hidden lg:block w-4/12">
              <NavigationComp mobile={false} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
